h2,
h4 {
  color: #e05215;
}

.onboarding-form {
  margin-top: 24px;
  box-shadow: 0 1px 3px rgba(80, 56, 56, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 20px;
}

.onboarding-form .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.onboarding-form:focus-within {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.MuiPaper-elevation1 button {
  float: right;
  margin: 40px 40px 0 0;
}

.introduction {
  line-height: 1.5;
  letter-spacing: 0.2;
}

.onboarding-questionnaire header,
.question-section header,
.onboarding-submission header {
  justify-content: space-between;
  display: flex;
}

.onboarding-questionnaire .onboarding-form {
  height: 248px;
  cursor: pointer;
}

.onboarding-questionnaire .onboarding-form h3 {
  color: #595d67;
  width: 56%;
  margin: 16px 0 56px 0;
  height: 32px;
  line-height: 1.5;
}

.onboarding-questionnaire .onboarding-form .component-icon {
  margin-bottom: 20px;
}

.onboarding-questionnaire .onboarding-form p {
  margin: 0;
  font-weight: bold;
  display: inline;
  vertical-align: top;
  padding-left: 8px;
}

.onboarding-questionnaire .onboarding-form .progress-bar div {
  border: 6px solid #f5f5f5;
  background-color: #f5f5f5;
  width: 360px;
  position: relative;
  top: 40px;
}

.onboarding-questionnaire .onboarding-form .progress-bar p {
  position: absolute;
  bottom: 16px;
  right: 0;
  font-size: 14px;
}

.onboarding-questionnaire .onboarding-form .progress-bar div div {
  border: 6px solid #e05215;
  background-color: #e05215;
  position: absolute;
  top: -6px;
  left: -6px;
}

.component-header h2 div {
  display: inline-block;
  padding-left: 12px;
  font-size: 20px;
  color: black;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-left: 12px;
  border: 2px solid #e05215;
}
.question-form h5 {
  font-size: 15px;
  margin-bottom: 20px;
  color: grey;
}

.question-form h4 {
  margin-bottom: 0;
}

.question-form h5 {
  margin-top: 20px;
}

.onboarding-upload {
  margin-top: 24px;
  margin-bottom: 24px;
}

.onboarding-upload .MuiBox-root {
  margin-top: 8px;
}

.file-upload p:first-child {
  margin-left: 8px;
}

fieldset {
  display: block !important;
}

div[role='radiogroup'] {
  flex-direction: row;
}

div[role='radiogroup'],
.onboarding-checkbox .MuiGrid-item {
  margin-bottom: 16px;
}

.onboarding-input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.onboarding-input-group .MuiGrid-item {
  margin-bottom: 16px;
}

hr {
  margin-top: 12px !important;
}

input[type='file'] {
  display: none;
}

.delete-file svg {
  margin-left: 52px;
}

.onboarding-text-area .MuiTextField-root {
  width: 100%;
}

.question-section button {
  float: right;
  margin-bottom: 36px;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

svg {
  cursor: pointer;
}

.onboarding-header h6 {
  color: #595d60;
  font-weight: bold;
}

.onboarding-header img {
  width: 64px;
  height: 64px;
}

.onboarding-header button {
  color: #595d60;
  font-weight: bold;
}

.onboarding-questionnaire,
.question-section,
.onboarding-submission {
  margin-top: 24px;
  margin-bottom: 24px;
}

.action-items {
  margin-right: 20px;
  display: flex;
}

.onboarding-tooltip {
  color: grey;
  vertical-align: middle;
  font-size: 1.2rem !important;
}

.MuiInput-multiline {
  margin-bottom: 16px;
}

.onboarding-dialog p {
  font-weight: bold;
  font-size: 16px;
}

.onboarding-dialog a {
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid #e05215;
}

.onboarding-dialog h2 {
  font-weight: bold;
}

.onboarding-checkbox {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.onboarding-submission h5 {
  text-align: justify;
  width: 80%;
}

.onboarding-submission button {
  margin-left: 24px;
  float: right;
}

.MuiFormHelperText-root {
  color: #e05215 !important;
  font-weight: bold !important;
}
